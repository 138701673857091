import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, ArcElement, ChartDataLabels);

interface BarChartProps {
  data: any;
  size: number;
  labels: string[];
}

const parseCategoryName = (name: string) => {
  return name.split('/')[1];
}

const BarChart = ({ data, size, labels }: BarChartProps) => {


  const chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: '#333',
          font: {
            size: 12,
            weight: 'bold',
          },
          maxRotation: 45,
          minRotation: 45,
          autoSkip: false,
          callback: function (value: any, index: any, values: any) {
            return parseCategoryName(labels[index]);
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        formatter: function(value: any, context: any) {
          return '';
        },
      },
    },
  };

  return (
    <div>
      <Bar
        id="bar"
        data={data}
        width={size}
        height={380}
        options={chartOptions}
      />
    </div>
  );
};

export default BarChart;
