import React, { useEffect, useState } from 'react';
import http from 'libs/http';
import { useDispatch, useSelector } from 'react-redux';
import { loaderActions } from 'store/loader/loader.store';
import {toast} from "react-toastify";
import { getSuggestions } from 'modules/suggestions/services/suggestions.store';


const Suggestions = () => {

  const dispatch: any = useDispatch();
  const loading = useSelector((state: any) => state.loaderStore.loading);
  const suggestions: any = useSelector((state: any) => state.suggestionsStore.suggestions);


  useEffect(() => {
    dispatch(getSuggestions());
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        dispatch(getSuggestions());
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return(
    <>
      {suggestions.length > 0 &&
          <section className="bg-white rounded-xl border overflow-hidden">
            {suggestions.length > 0 && suggestions.map((s: any) =>
              <div className="flex items-center gap-x-5 py-8 border-b hover:bg-gray-100/50">
                <div className="w-96 pl-4 text-base-600 font-medium">{s.author.email ? s.author.email : '/'}</div>
                <div className="w-7/12 pl-5 border-l font-medium text-black/85" dangerouslySetInnerHTML={{ __html: s.text }}></div>
              </div>
            )}
          </section>
      }
      {suggestions.length === 0 && <div className="flex items-center justify-center h-[calc(100vh-250px)] text-gray-700 font-medium">Nema pronađenih sugestija!</div>}
    </>
  );
};

export default Suggestions;