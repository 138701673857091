import React, {useEffect, useState, forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import { faCircleXmark, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DatepickerProps {
  placeholder?: string;
  value?: any;
  format?: string;
  classNames?: string;
  onChange: (date: any) => void;
}

const CustomInput = forwardRef<HTMLInputElement, { value: string; onClick: () => void; onClear: () => void; placeholderText?: string }>(({ value, onClick, onClear, placeholderText }, ref) => (
  <div className="relative">
    <div className="absolute left-3 top-0 z-30 h-full flex items-center">
      <FontAwesomeIcon icon={faCalendar} className="text-gray-300" />
    </div>
    <input
      type="text"
      value={value}
      readOnly
      ref={ref}
      onClick={onClick}
      placeholder={placeholderText}
      className="w-full pl-9 h-10 z-20 relative rounded-lg border hover:border-base-500 focus:ring-2 focus:ring-base-500 focus:outline-0 animation duration-200 ease-in-out"
    />
    {value && (
      <button
        onClick={() => onClear()}
        className="absolute right-2 top-2 text-red-600 z-20"
      >
        <FontAwesomeIcon icon={faCircleXmark} />
      </button>
    )}
  </div>
));


const Datepicker = ({ value, classNames, format, placeholder, onChange }: DatepickerProps) => {
  const [date, setDate] = useState<any>('');

  const onChangeHandler = (date: string) => {
    setDate(date);
    onChange(date);
  }

  useEffect(() => {
    if (value) setDate(value);
  }, []);

  return (
    <DatePicker
      selected={date}
      dateFormat={format}
      icon={true}
      onSelect={(value: any) => onChangeHandler(value)}
      onChange={(value: any) => onChangeHandler(value)}
      customInput={
        <CustomInput
          onClick={() => null}
          value={date ? date : ''}
          onClear={() => onChangeHandler('')}
          placeholderText={placeholder}
        />
      }
    />


    // <DatePicker
    //   selected={date}
    //   dateFormat={format}
    //   toggleCalendarOnIconClick
    //   showIcon
    //   // icon={
    //   //   <svg className="z-30 mt-[3px] transform -translate-x-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="16" width="16" role="img" aria-hidden="true" data-icon="calendarAlt">
    //   //     <path fill="#d1d5db" d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"></path>
    //   //   </svg>
    //   // }
    //   // isClearable={value}
    //   icon={date ? <FontAwesomeIcon onClick={() => onChangeHandler('')} icon={faCircleXmark} className="absolute right-0 top-1 z-50 text-red-600" /> : null}
    //   placeholderText={placeholder}
    //   onSelect={(value: any) => onChangeHandler(value)}
    //   onChange={(value: any) => onChangeHandler(value)}
    //   className={`w-full pl-12 h-10 z-20 relative rounded-lg border hover:border-base-500 focus:ring-2 focus:ring-base-500 focus:outline-0 animation duration-200 ease-in-out ${classNames}`}
    // />
  )
}

export default Datepicker;