import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {currencyFormatter} from 'libs/helpers';
import BarChart from 'components/BarChart';
import PieChart from 'components/PieChart';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import useWidth from 'hooks/useWidth';
import PieHalfChart from 'components/PieHalfChart';
import { getCompany } from 'modules/benefit/services/benefit.store';
import { getBudgets } from 'modules/analytics/services/analytics.store';

const countryOptions = [
  { label: 'SRB', value: 'sER', key: 1 },
  { label: 'BIH', value: 'bIH', key: 2 },
  { label: 'MNE', value: 'mNE', key: 3 },
]

const PIE_CHART_COLORS = ["#1090CCFF", "#DBA32A", "#BFBAB8", "#59a058", "#1cd8cc", "#a11ec9", "#d4f24d", "#95cced" , "#ff4800", "#4a4e54"];

const PIE_CHART_LEGEND = [
  { name: 'Tehnologija', color: '#1090CCFF' },
  { name: 'Transport', color: '#DBA32AFF' },
  { name: 'Zdravlje', color: '#808181' },
  { name: 'Restorani i marketi', color: '#F9B397' },
  { name: 'Lični razvoj', color: '#BFBAB8' },
  { name: 'Lepota i moda', color: '#89B7DF' },
  { name: 'Team Building', color: '#A1CB8B' },
  { name: 'Dečiji kutak', color: '#828CA8' },
  { name: 'Dobročinstvo', color: '#C18F7E' },
  { name: 'Uređenje doma', color: '#C0A478' },
]

const Analytics = () => {

  const dispatch: any = useDispatch();
  const user: any = useSelector((state: any) => state.authStore.user);
  const budgets: any = useSelector((state: any) => state.analyticsStore.budgets);
  const company: any = useSelector((state: any) => state.benefitStore.company);
  const [country, setCountry] = useState(countryOptions[0]);
  const [barChartData, setBarChartData] = useState<any>();
  const [pieChartData, setPieChartData] = useState<any>();
  const [pieHalfChartData, setPieHalfChartData] = useState<any>();
  const width = useWidth();
  const [sizePie, setSizePie] = useState(250);
  const [sizeBar, setSizeBar] = useState(250);
  const [pieHalfSize, setPieHalfSize] = useState(250);
  const [topCategories, setTopCategories] = useState<any>([]);
  const [percentOfPurchasedBenefits, setPercentOfPurchasedBenefits] = useState<any>(0);
  const [averageNumberOfPurchasedBenefitsPerEmployee, setAverageNumberOfPurchasedBenefitsPerEmployee] = useState<any>(0);

  useEffect(() => {
    if (width > 640) setSizePie(400);
    if (width > 768) setSizePie(600);
    if (width > 1023) setSizePie(225);

    if (width > 640) setPieHalfSize(400);
    if (width > 768) setPieHalfSize(600);
    if (width > 1023) setPieHalfSize(200);

    if (width > 640) setSizeBar(200);
    if (width > 768) setSizeBar(400);
    if (width > 1023) setSizeBar(210);
  }, [width]);


  useEffect(() => {
    if (company) {
      countryOptions.forEach((c: any) => {
        if (company && c.key === company.country) {
          setCountry(c);
        }
      })
    }
  }, [company]);

  console.log(budgets, 'budgets')

  const getPieChartData = (): void => {
    if (budgets) {
      setPieChartData({
        labels: budgets ? [...budgets.spentPerCategory[country.value].categories] : [],
        // labels: [],
        datasets: [
          {
            label: user.company.name,
            backgroundColor: ["#1090CCFF", "#DBA32A", "#BFBAB8", "#59a058", "#1cd8cc", "#a11ec9", "#d4f24d", "#95cced" , "#ff4800", "#4a4e54", "#C0A478", "#86303A", "#41112C", "#FF6C37", "#8DCA51"],
            borderColor: '#FFFFFF',
            borderWidth: 2,
            hoverBackgroundColor: 'rgb(4,108,156)',
            hoverBorderColor: 'rgb(4,108,156)',
            data: budgets ? [...budgets.spentPerCategory[country.value].values] : []
          },
        ],
      });
    }
  }

  const getPieHalfChartData = (): void => {
    if (budgets) {
      setPieHalfChartData({
        // labels: budgets ? [...budgets.spentPerCategory[country.value].categories] : [],
        labels: ['Preostalo', 'Potrošeno'],
        datasets: [
          {
            label: user.company.name,
            backgroundColor: ["#1090CCFF", "#DBA32A"],
            borderColor: '#FFFFFF',
            borderWidth: 2,
            hoverBackgroundColor: '#046C9C',
            hoverBorderColor: '#046C9CFF',
            data: budgets ? [100 - budgets.budgetPercentage[country.value], budgets.budgetPercentage[country.value]] : []
          },
        ],
      });
    }
  }

  const getBarChartData = (): void => {
    if (budgets) {
      setBarChartData({
        labels: budgets ? [...budgets.benefitCategoryCount[country.value].categories] : [],
        datasets: [
          {
            label: 'Broj kupljenih benefita po kategoriji',
            backgroundColor: ["#1090CCFF"],
            // borderColor: 'rgb(4,108,156)',
            // borderWidth: 1,
            hoverBackgroundColor: '#046C9C',
            hoverBorderColor: 'rgb(4,108,156)',
            data: budgets ? [...budgets.benefitCategoryCount[country.value].values] : []
          },
        ],
      });
    }
  };

  const getTopCategories = () => {
    if (! budgets || !budgets.spentPerCategory) return;
    const values = budgets.spentPerCategory[country.value].values;
    const categories = budgets.spentPerCategory[country.value].categories;

    const concatenatedArray = categories.map((key: any, index: number) => {
      return { key: key, value: values[index] };
    });
    const sortedArray = concatenatedArray.sort((a: any, b: any) => b.value - a.value).slice(0, 5);
    setTopCategories(sortedArray);
  };

  const parseCategoryName = (name: string) => {
    if (name.split('/')[1]) return name.split('/')[1];
    return name;
  }

  const fetchBudgets = async () => {
    try {
      await dispatch(getBudgets());
      getTopCategories();
    } catch (e: any) {
      toast.error('Nešto nije u redu!')
    }
  }

  const fetchCompany = async () => {
    try {
      await dispatch(getCompany());
    } catch (e: any) {
      console.log(e, 'err');
    }
  }

  useEffect((): void => {
    fetchCompany();
    fetchBudgets();
  }, []);

  useEffect(() => {
    if (country && budgets) {
      // setPercentOfPurchasedBenefits(budgets.spent90PercentOrMoreBudget[country.value] / budgets.employeesCount[country.value] * 100);
      setPercentOfPurchasedBenefits(budgets.spent90PercentOrMoreBudget[country.value]);
      setAverageNumberOfPurchasedBenefitsPerEmployee(budgets.sumBenefitsCount[country.value] / ((budgets.percentageEmployeesOnePlusBenefit[country.value] / 100) * budgets.employeesCount[country.value]));
    }
  }, [budgets, country]);

  useEffect((): void => {
    getPieChartData();
    getBarChartData();
    getPieHalfChartData();
    getTopCategories();
  }, [budgets, country]);



  return (
    <>
      <section className="flex items-center justify-between select-none">
        <div className="relative mx-auto lg:mx-0 flex items-center justify-between font-medium bg-white rounded-lg w-[222px] cursor-pointer select-none">
          <div className={`absolute ${country.value === countryOptions[0].value && 'left-0'} ${country.value === countryOptions[1].value && 'transform translate-x-[100%]'} ${country.value === countryOptions[2].value && 'transform translate-x-[200%]'} top-0 bg-base-600 w-[74px] h-full rounded-lg animation duration-200 ease-in-out`}></div>
          {countryOptions.map((c: any) =>
            <p key={c.value} className={`${country.value === c.value && 'text-white'} relative w-[74px] text-center rounded-full py-1 animation duration-300 ease-in-out`}>{ c.label }</p>
          )}
        </div>
        <div className="w-[222px] h-9 bg-base-500 rounded-lg flex items-center overflow-hidden border-2 border-base-600">
          <div className="w-1/2 h-9 bg-white flex items-center justify-center font-medium rounded-r-lg">
            Period
          </div>
          <div className="w-1/2 h-9 bg-base-500 font-medium text-white flex items-center justify-center">
            {new Date().getFullYear()}
          </div>
        </div>
      </section>

      <section className="my-6">
        {budgets && <div className="w-full flex items-start gap-5 lg:gap-2">
          <section className="lg:w-[220px] space-y-4">
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="1"/>
              <dt
                data-tooltip-id="1"
                data-tooltip-content="UKUPNO DODELJENO BUDŽETA"
                className="text-sm font-medium text-gray-500 truncate tracking-tighter"
              >
                UKUPNO DODELJENO BUDŽETA
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {currencyFormatter(budgets.baseBalancesSum[country.value].baseBalanceSum)} {budgets.baseBalancesSum[country.value].currency}
              </dd>
            </div>
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="2"/>
              <dt
                data-tooltip-id="2"
                data-tooltip-content="UKUPNO POTROŠENO BUDŽETA"
                className="text-sm font-medium text-gray-500 truncate tracking-tighter">
                UKUPNO POTROŠENO BUDŽETA
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {currencyFormatter(budgets.spent[country.value].spentSum)} {budgets.spent[country.value].currency}
              </dd>
            </div>
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="3"/>
              <dt
                className="text-sm font-medium text-gray-500 truncate"
                data-tooltip-id="3"
                data-tooltip-content="% ISKORIŠĆENOG BUDŽETA"
              >
                % ISKORIŠĆENOG BUDŽETA
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {currencyFormatter(budgets.budgetPercentage[country.value])} %
              </dd>
            </div>
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="4"/>
              <dt
                className="text-sm font-medium text-gray-500 truncate"
                data-tooltip-id="4"
                data-tooltip-content="UKUPNO KUPLJENO BENEFITA"
              >
                UKUPNO KUPLJENO BENEFITA
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {/*{currencyFormatter(budgets.sumBenefits[country.value])}*/}
                {budgets.sumBenefitsCount[country.value] ? budgets.sumBenefitsCount[country.value] : '0'}
              </dd>
            </div>
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="5"/>
              <dt
                className="text-sm font-medium text-gray-500 truncate"
                data-tooltip-id="5"
                data-tooltip-content="PROSEČAN BROJ KUPLJENIH BENEFITA PO ZAPOSLENOM"
              >
                PROSEČAN BROJ KUPLJENIH BENEFITA PO ZAPOSLENOM
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {/*{budgets.averageNumberOfBenefits[country.value] ? budgets.averageNumberOfBenefits[country.value] : '0'}*/}
                {Math.round(averageNumberOfPurchasedBenefitsPerEmployee)}
              </dd>
            </div>
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="13"/>
              <dt
                className="text-sm font-medium text-gray-500 truncate"
                data-tooltip-id="13"
                data-tooltip-content="BROJ KORIŠĆENIH PROVAJDERA"
              >
                BROJ KORIŠĆENIH PROVAJDERA
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {budgets.providersCount[country.value]}
              </dd>
            </div>

            <div className="px-3 py-5 bg-white rounded-xl overflow-hidden">
              <dt
                className="text-sm font-medium text-gray-500 truncate mb-2"
              >
                TOP 5 PROVAJDERA
              </dt>
              {budgets && budgets.topProviders[country.value].map((p: any, i: number) =>
                <>
                  <Tooltip id={String(i + 10)}/>
                  <dd
                    data-tooltip-id={String(i + 10)}
                    data-tooltip-content={`${i + 1}. ${p}`}
                    className="mt-1 text-md font-semibold text-gray-900 truncate">
                    {i + 1}. {p}
                  </dd>
                </>
              )}
            </div>
          </section>

          <section className="w-[58.5%] mx-auto">
            {pieChartData && barChartData && Object.keys(pieChartData).length > 0 &&
              <div className="space-y-4">
                <div className="grid gird-cols-1 lg:grid-cols-2 gap-8 py-3 bg-white shadow-md rounded-xl">
                  <div className="flex items-start justify-center">
                    {company && <div className="space-y-5">
                      <p className="text-center font-bold text-gray-700">{company.name}</p>
                      <img src={company.picture} className="h-36 w-36 rounded-full shadow-lg" alt=""/>
                    </div>}
                  </div>
                  <div className="flex items-start justify-center">
                    <div>
                      <p className="text-center font-bold text-gray-700">Pregled stanja budžeta</p>
                      <PieHalfChart key="pie-half" data={pieHalfChartData} size={pieHalfSize}/>
                      <div className="flex items-center justify-between px-10 transform -translate-y-2.5">
                        <Tooltip id="remaining"/>
                        <p
                          data-tooltip-id="remaining"
                          data-tooltip-content={`Preostalo: ${100 - budgets.budgetPercentage[country.value]}%`}
                          className="text-base-600 font-medium">
                          Preostalo
                        </p>
                        <Tooltip id="spent"/>
                        <p
                          data-tooltip-id="remaining"
                          data-tooltip-content={`Potrošeno: ${budgets.budgetPercentage[country.value]}%`}
                          className="text-orange-550 font-medium">
                          Potrošeno
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center py-3 bg-white shadow-md rounded-xl">
                  <p className="font-bold text-gray-700">Potrošnja budžeta po kategoriji</p>
                  <div className="grid grid-cols-2">
                    <div>
                      <PieChart key="pie" data={pieChartData} size={sizePie}/>
                    </div>
                    <div className="flex items-center justify-center">
                      <ul className="grid grid-cols-2 gap-y-5 gap-x-2.5">
                        {PIE_CHART_COLORS.slice(0, 10).map((color: any, index: number) =>
                            budgets && budgets.spentPerCategory && budgets.spentPerCategory[country.value].categories.slice(0, 10).map((item: any, i: number) =>
                              (i === index &&
                                <li key={item} className="flex items-center gap-x-1">
                                  <div className="h-3 w-3 rounded" style={{backgroundColor: color}}></div>
                                  <p className="text-sm">{parseCategoryName(item)}</p>
                                </li>
                              )
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="bg-white shadow-md rounded-xl pt-3">
                  <p className="text-center font-bold text-gray-700">Broj kupljenih benefita po kategoriji</p>
                  <BarChart
                    key="bar"
                    data={barChartData}
                    size={sizeBar}
                    labels={budgets.spentPerCategory[country.value].categories}
                  />
                </div>
              </div>
            }
          </section>


          <section className="space-y-4 lg:w-[220px]">
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="10"/>
              <dt
                className="text-sm font-medium text-gray-500 truncate tracking-tighter"
                data-tooltip-id="10"
                data-tooltip-content="UKUPNO ZAPOSLENIH U PROGRAMU"
              >
                UKUPNO ZAPOSLENIH U PROGRAMU
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {budgets.employeesCount[country.value]}
              </dd>
            </div>
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="7"/>
              <dt
                className="text-sm font-medium text-gray-500 truncate"
                data-tooltip-id="7"
                data-tooltip-content="MINIMALAN BUDŽET"
              >
                MINIMALAN BUDŽET
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {currencyFormatter(budgets.budgets[country.value].min)} {budgets.spent[country.value].currency}
              </dd>
            </div>
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="8"/>
              <dt
                className="text-sm font-medium text-gray-500 truncate"
                data-tooltip-id="8"
                data-tooltip-content="MAKSIMALAN BUDŽET"
              >
                MAKSIMALAN BUDŽET
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {currencyFormatter(budgets.budgets[country.value].max)} {budgets.spent[country.value].currency}
              </dd>
            </div>
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="9"/>
              <dt
                className="text-sm font-medium text-gray-500 truncate tracking-tighter"
                data-tooltip-id="9"
                data-tooltip-content="PROSEČAN BUDŽET PO ZAPOSLENOM"
              >
                PROSEČAN BUDŽET PO ZAPOSLENOM
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {currencyFormatter(budgets.budgets[country.value].average)} {budgets.spent[country.value].currency}
              </dd>
            </div>
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="11"/>
              <dt
                className="text-sm font-medium text-gray-500 truncate tracking-tighter"
                data-tooltip-id="11"
                data-tooltip-content="% ZAPOSLENIH KOJI SU KUPILI MAKAR JEDAN BENEFIT"
              >
                % ZAPOSLENIH KOJI SU KUPILI MAKAR JEDAN BENEFIT
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {budgets.percentageEmployeesOnePlusBenefit[country.value]} %
              </dd>
            </div>
            <div className="px-3 py-7 bg-white shadow-md rounded-xl overflow-hidden">
              <Tooltip id="12"/>
              <dt
                className="text-sm font-medium text-gray-500 truncate"
                data-tooltip-id="12"
                data-tooltip-content="% ZAPOSLENIH KOJI SU POTROŠILI CEO BUDŽET"
              >
                % ZAPOSLENIH KOJI SU POTROŠILI CEO BUDŽET
              </dt>
              <dd className="mt-1 text-xl font-semibold text-gray-900">
                {/*{budgets.spent90PercentOrMoreBudget[country.value] ? budgets.spent90PercentOrMoreBudget[country.value] + ' %' : '-'}*/}
                { currencyFormatter(percentOfPurchasedBenefits) } %
              </dd>
            </div>
            <div className="px-3 py-5 bg-white shadow-md rounded-xl overflow-hidden">
              <dt
                className="text-sm font-medium text-gray-500 truncate mb-2"
              >
                TOP 5 KATEGORIJA
              </dt>
              {topCategories.map((c: any, i: number) =>
                <>
                  <Tooltip id={String(c.key + 20)}/>
                  <dd
                    data-tooltip-id={String(c.key + 20)}
                    data-tooltip-content={`${i + 1}. ${parseCategoryName(c.key)}`}
                    className="mt-1 text-md font-semibold text-gray-900 truncate">
                    {i + 1}. {parseCategoryName(c.key)}
                  </dd>
                </>
              )}
            </div>
          </section>
        </div>
        }
      </section>
    </>
  );
};

export default Analytics;