import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'modules/auth/services/auth.store';
import { Route, Routes, useLocation, Outlet, Navigate } from 'react-router-dom';

import Login from 'pages/login';
import Contact from 'pages/contact';
import Employees from 'pages/employees';
import Analytics from 'pages/analytics';
import Downloads from 'pages/downloads';
import Suggestions from 'pages/suggestions';
import Restrictions from 'pages/restrictions';
import Transactions from 'pages/transactions';
import FixedBenefits from 'pages/fixed-benefits';
import Reimbursements from 'pages/reimbursements';


const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');

  const tokenHandler = () => {
    if(token) dispatch(authActions.setToken(token));
    else dispatch(authActions.logOut());
  }

  useEffect(() => {
    window.addEventListener('storage', () => {
      tokenHandler();
    })
  }, []);

  useEffect(() => {
    tokenHandler();
  }, [location.pathname]);

  return (
    token && user ? <Outlet /> : <Navigate to="/login" />
  );
};


const Router = () => {
  return (
    <Routes>
      {/* OPEN ROUTES HERE */}
      <Route path="/login" element={<Login />} />

      <Route element={<ProtectedRoutes />}>
        {/* PROTECTED ROUTES HERE */}
        <Route path="/" element={<Analytics />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/employees" element={<Employees />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/suggestions" element={<Suggestions />} />
        <Route path="/restrictions" element={<Restrictions />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/fixed-benefits" element={<FixedBenefits />} />
        <Route path="/reimbursements" element={<Reimbursements />} />
        <Route path="*" element={<div>404 PAGE NOT FOUND</div>} />
      </Route>
    </Routes>
  );
};




export default Router;